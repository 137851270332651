import { useEffect } from 'react'

import { useAppData } from 'lib/context/app-data-context'
import { noop } from 'lib/utils'

// Note: This is temporary solution to share page specific trackEvent object to
// the components used by layout as there is no way in nextjs can share props
// from page to layout's component directly

// Tech Doc: https://pelago.atlassian.net/wiki/spaces/PEL/pages/1906900993/SSR+-+Track+Event+Integration

// TODO: keep watching future nextjs release in case they share some way
// to use page's props in layout's components || hook use in layout method
const usePageTrackEvent = ({ trackEvent }: { trackEvent: TrackEventType }) => {
  const { setTrackEvent } = useAppData()
  useEffect(() => {
    setTrackEvent(trackEvent)
    return () => {
      setTrackEvent(noop)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

export default usePageTrackEvent
