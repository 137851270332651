import React, { FC } from 'react'
import cn from 'classnames'

import { Sizes } from 'ui/icon'
import { Button } from 'ui/button'

import s from './styles.module.scss'

interface SliderArrowProps {
  isPrev?: boolean
  arrowSize?: Sizes
  className?: string
  dots?: boolean
  onClick?: (e: any) => void
}

const SliderArrow: FC<SliderArrowProps> = ({ onClick, isPrev, arrowSize = '', className, dots }) => {
  // dont render arrows on first/last page - https://github.com/akiran/react-slick/issues/1517
  if (className?.includes('slick-disabled')) return null
  return (
    <Button
      iconName={isPrev ? 'caret-left' : 'caret-right'}
      variant="tertiary-outline"
      shape="circle"
      size="large"
      className={cn(s.sliderArrow, 'slider-arrow flex-center', {
        [s._prev]: isPrev,
        [s.sliderDots]: dots,
        [s[arrowSize]]: !!arrowSize,
      })}
      onClick={onClick}
    />
  )
}

export default SliderArrow
