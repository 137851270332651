import React, { FC, useMemo } from 'react'
import cn from 'classnames'

import { Button } from 'ui/button'
import { Image } from 'ui/image'

import { AspectRatio } from 'components/aspect-ratio'
import { Link } from 'components/link'
// import { ImportantCallout } from 'components/important-callout'
// import { useTranslation } from 'next-i18next'
import { useDestinationSwitcher } from 'components/destination-switcher/hooks'

import { useGlobalContext } from 'lib/context/global-context'
import { buildPath, noop } from 'lib/utils'

import { DESTINATION_ROUTE_V1, POINT_OF_INTEREST_ROUTE_V1, THEME_ROUTE } from 'lib/constants/routes'

import s from './styles.module.scss'

export type Poi = {
  collectionCode: number
  collectionLabel: string
  collectionUri: string
  collectionType: string
  collectionDescription: string
  collectionMediaData: any
  destinationIds: string[]
}
export type PoiCardProps = {
  loading?: boolean
  data?: Poi | CountryDestination
  size?: 'small' | 'large' | 'x-large'
  ctaText: string
  indexInList?: number
  onClick?: () => void
}

const PoiCard: FC<PoiCardProps> = ({
  loading,
  data,
  size = 'small',
  // indexInList,
  ctaText,
  onClick = noop,
  children,
}) => {
  const { isMobileView, isTabletView } = useGlobalContext()
  const { getDestinationInfo } = useDestinationSwitcher()

  // const { t } = useTranslation(['common'])
  const poi = data as Poi
  const destination = data as CountryDestination

  const defaultAspectRatio = useMemo(() => {
    return isTabletView || isMobileView ? 16 / 9 : 1
  }, [isTabletView, isMobileView])

  if (loading) {
    return (
      <div className={cn(s.card, s.loading)}>
        <AspectRatio ratio={defaultAspectRatio}>
          <div className={s.image} />
        </AspectRatio>
        <div className={cn(s.info, s[`size-${size}`])}>
          <h4 />
          <div>
            <p />
          </div>
          {/* eslint-disable-next-line i18next/no-literal-string */}
          <Button variant="tertiary-outline" size="small" />
        </div>
      </div>
    )
  }

  const {
    collectionCode,
    collectionLabel,
    collectionUri,
    collectionType,
    collectionMediaData,
    collectionDescription,
    destinationIds,
  } = poi || {}

  const { countryId, destinationId, destinationName, mediaData } = destination || {}
  const { destinationUri, destinationCode } = (destinationId && getDestinationInfo(destinationId)) || {}

  const [url, imageData, title, description] = countryId
    ? [
        buildPath(DESTINATION_ROUTE_V1, {
          destinationId: destinationUri,
          destinationCode: `${destinationCode}`,
        }),
        mediaData?.[0],
        destinationName,
        destination?.description,
      ]
    : [
        buildPath(collectionType === 'poi' ? POINT_OF_INTEREST_ROUTE_V1 : THEME_ROUTE, {
          collectionSlug: collectionUri,
          collectionCode: `${collectionCode}`,
          ...(collectionType === 'poi' ? { destinationSlug: destinationIds[0] } : {}),
        }),
        collectionMediaData?.[0],
        collectionLabel,
        collectionDescription,
      ]

  return (
    <Link passHref href={url}>
      <a className={cn(s.card, 'cursor-pointer')} onClick={() => onClick?.()}>
        {/* As DS team is not ready with the data, commenting this label for now */}
        {/* {indexInList === 0 && (
          <ImportantCallout
            label={t('t.mostPopular')}
            position="top-12"
            size={isMobileView ? 'small' : 'medium'}
            iconName="fire"
          />
        )} */}
        <Image
          src={imageData}
          size="medium"
          aspectRatio={defaultAspectRatio}
          className={s.image}
          alt={title}
        />
        <div className={s.info}>
          <h4>{title}</h4>
          <div className="mt-1">
            <p>{description}</p>
          </div>
          <Button variant="tertiary-outline" size="small">
            {ctaText}
          </Button>
        </div>
        {children}
      </a>
    </Link>
  )
}

export { PoiCard }
