import { useCallback } from 'react'
import { useRouter } from 'next/router'
import format from 'date-fns/format'

import { useGlobalContext } from 'lib/context/global-context'

const useDateCurrency = () => {
  const router = useRouter()
  const { currentCurrency } = useGlobalContext()

  type MonthFormats = 'numeric' | '2-digit' | 'long' | 'short' | 'narrow' | undefined

  const getMonthNames = useCallback(
    (format: MonthFormats = 'long', locale = router.locale): string[] => {
      const months = Array(12)
        .fill(null)
        .map((_, monthIndex) => new Date(Date.UTC(new Date().getFullYear(), monthIndex)))
      try {
        const formatter = new Intl.DateTimeFormat(locale, { month: format, timeZone: 'UTC' })
        return months.map((date) => formatter.format(date))
      } catch (error) {
        console.error(error)
        const shortMonthNames = [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec',
        ]
        return months.map((date) => shortMonthNames[date.getMonth()])
      }
    },
    [router.locale]
  )

  const formatNumberDecimalParts = useCallback(
    (_number: number, digitsAfterDot = 2, locale: string | undefined = router.locale) => {
      const number = Number(_number) ?? 0

      try {
        const formatter = new Intl.NumberFormat(locale, {
          minimumFractionDigits: digitsAfterDot,
          maximumFractionDigits: digitsAfterDot,
        })

        const parts = formatter.formatToParts(number)

        const decimal = parts.find((item) => item.type === 'decimal')?.value
        const fraction = parts.find((item) => item.type === 'fraction')?.value
        const integer = parts
          .filter((item) => item.type !== 'fraction' && item.type !== 'decimal')
          .map((item) => item.value)
          .join('')

        return { decimal, fraction, integer }
      } catch (error) {
        console.error(error)
        return { integer: number }
      }
    },
    [router.locale]
  )

  const formatNumberDecimal = useCallback(
    (
      _number: number,
      digitsAfterDot = 2,
      skipDecimalForInteger = true,
      locale: string | undefined = router.locale
    ) => {
      const number = Number(_number) ?? 0

      const options = {
        minimumFractionDigits: skipDecimalForInteger && Number.isInteger(number) ? 0 : digitsAfterDot,
        maximumFractionDigits: skipDecimalForInteger && Number.isInteger(number) ? 0 : digitsAfterDot,
      }

      try {
        const formatter = new Intl.NumberFormat(locale, options)

        return formatter.format(number)
      } catch (error) {
        console.error(error)
        return number
      }
    },
    [router.locale]
  )

  const formatDate = useCallback(
    (date: Date, options: Intl.DateTimeFormatOptions = {}, forceLocale?: string) => {
      const { locale } = router
      const _locale = forceLocale || locale
      try {
        const defaultOptions: Intl.DateTimeFormatOptions = {
          day: 'numeric',
          month: 'short',
          year: 'numeric',
          ...((_locale === 'th-TH' || _locale === 'th') && { calendar: 'gregory' }),
          // th-TH/th loads budhist year which is not supported by react-datepicker calendar lib we used
          // hence we continue using english(gregory) year for th-TH/th
        }

        const formatter = new Intl.DateTimeFormat(_locale, {
          ...defaultOptions,
          ...options,
        })

        return formatter.format(date)
      } catch (error) {
        console.error(error)
        return format(date, 'MMM dd, yyyy')
      }
    },
    [router]
  )

  const formatNumber = useCallback(
    (number: any, options: Intl.NumberFormatOptions = {}, locale: string | undefined = router.locale) => {
      try {
        const formatter = new Intl.NumberFormat(locale, options)
        return formatter.format(number)
      } catch (error) {
        console.error(error)
        return number
      }
    },
    [router.locale]
  )

  const currency = useCallback(
    (
      amount: number,
      { currency = currentCurrency, ...others }: { currency?: string; minimumFractionDigits?: number } = {}
    ): string => {
      try {
        const formatter = new Intl.NumberFormat(router.locale, {
          style: 'currency',
          currency,
          ...others,
        })

        return formatter.format(amount)
      } catch (error) {
        console.error(error)
        return `${currency} ${amount}`
      }
    },
    [currentCurrency, router.locale]
  )

  return {
    currency,
    formatDate,
    formatNumber,
    getMonthNames,
    formatNumberDecimal,
    formatNumberDecimalParts,
  }
}

export default useDateCurrency
