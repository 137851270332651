import { useRouter } from 'next/router'
import { useEffect, useRef } from 'react'
import { uniqueId } from 'lodash-es'

import { Image } from 'ui/image'
import { Button } from 'ui/button'

import { Link } from 'components/link'
import { ExternalLink } from 'components/external-link'
import { OngoingAppBanner } from 'components/mobile-app-banner/ongoing-app-banner'

import useAuth from 'lib/hooks/useAuth'

import { useGlobalContext } from 'lib/context/global-context'
import { getIsAbsoluteUrl } from 'lib/utils'

import { EVENTS } from 'lib/constants/events'
import { SUPPORTED_LOCALES_WITH_LEGACY } from 'lib/constants'

import PromoAbstract from 'brand-assets/illustrations/promo-abstract.svg'

import s from './styles.module.scss'

type OngoingCardProps = {
  data: {
    thumbnail: MediaItem
    redirectUri: string
    type?: 'custom'
  }
  trackEvent: TrackEventType
  indexInList: number
  onClick: () => void
}

const OngoingCard = ({ data, onClick, trackEvent }: OngoingCardProps) => {
  const {
    isMobileView,
    globalArgs: { rewardsBanner, appTouchpointsData },
  } = useGlobalContext()
  const { isEnabled: isMobileAppTouchPointEnabled } = appTouchpointsData
  const { locale } = useRouter()
  const idRef = useRef(uniqueId())

  const { link, header, button } = rewardsBanner

  const { authModal, isLoggedIn } = useAuth()

  useEffect(() => {
    // some weird bugs happens when user are logged in
    // React renders the wrong UI and data even tho we have pass the correct data
    // by having this logic, we'll make sure to generate a unique id again once user logged in
    // so the key of this component are unique and React are able to render properly
    if (isLoggedIn) {
      idRef.current = uniqueId()
    }
  }, [isLoggedIn])

  const onSignupNowClick = () => {
    trackEvent({ attributeId: EVENTS.SIGNUP_NOW_BY_PROMO, attributeType: EVENTS.ATTRIBUTES_TYPE.BUTTON })
    authModal.open({ screen: 'signup' })
  }

  const getRedirectUrl = (url: string) => {
    const isAbsoluteUrl = getIsAbsoluteUrl(url)
    const baseParts = isAbsoluteUrl ? url.split('//') : url.split('/')
    // e.g. ["traveller.dev.pelago.com", "en", "activity", "some product id"] for absolute url
    // ["en", "activity", "some product id"] for relative url
    const urlParts = isAbsoluteUrl ? baseParts[1].split('/') : baseParts
    const urlLocale = urlParts[1]

    if (SUPPORTED_LOCALES_WITH_LEGACY.includes(urlLocale)) {
      // Replace url locale with user's locale
      urlParts.splice(1, 1, locale as string)

      const urlJoined = urlParts.join('/')
      // if it's absolute url then assembling back as full url
      const redirectUrl = isAbsoluteUrl ? `${baseParts[0]}//${urlJoined}` : urlJoined
      return redirectUrl
    }

    return url
  }

  if (data.type === 'custom') {
    if (isMobileAppTouchPointEnabled) {
      return <OngoingAppBanner trackEvent={trackEvent} />
    }

    return (
      <div className={s.container}>
        <div className={s.signupPromoCard}>
          <PromoAbstract className={s.illustration} />
          <div className={s.content}>
            <h3 className={s.title}>{header}</h3>
            <div className={s.footer}>
              {button && (
                <Button
                  onClick={onSignupNowClick}
                  variant="primary-neon"
                  size={isMobileView ? 'small' : 'medium'}
                >
                  {button.label}
                </Button>
              )}
              {link && <ExternalLink href={link.url}>{link.label}</ExternalLink>}
            </div>
          </div>
        </div>
      </div>
    )
  }

  const redirectUrl = getRedirectUrl(data.redirectUri)

  return (
    <div key={idRef.current} className={s.container}>
      <Link href={redirectUrl}>
        <a className={s.ongoingCard} onClick={onClick}>
          <Image src={data.thumbnail} alt="" lazyLoad layout="fill" className={s.image} />
        </a>
      </Link>
    </div>
  )
}

export { OngoingCard }
