import React from 'react'
import cn from 'classnames'

import { Button } from 'ui/button'
import { Image } from 'ui/image'

import { AspectRatio } from 'components/aspect-ratio'
import { Link } from 'components/link'

import { useGlobalContext } from 'lib/context/global-context'
import { noop } from 'lib/utils'

import s from './styles.module.scss'

type CollectionCardProps = {
  loading?: boolean
  data?: Collection
  isSliderItem?: boolean
  imageProps?: any
  onClick?: () => void
  aspectRatio?: number | null
  indexInList?: number
}

const CollectionCard = ({
  loading,
  data,
  isSliderItem = false,
  imageProps,
  onClick = noop,
  aspectRatio,
  indexInList = 0,
}: CollectionCardProps) => {
  const { isMobileView } = useGlobalContext()

  if (loading) {
    return (
      <div className={cn(s.card, s.loading)}>
        {/* TODO in SSR while copying this, declare below size constants in constant file */}
        <AspectRatio ratio={aspectRatio || 290 / 193}>
          <div className={s.productImage} />
        </AspectRatio>
        <div className={s.collectionInfo}>
          <div className={s.subHeader} />
          <div className={s.header} />
        </div>
      </div>
    )
  }

  if (!data) return null

  const { header, subHeader, link, thumbnail } = data
  return (
    <Link passHref href={`/${link.url}`}>
      <div
        className={cn(s.card, 'cursor-pointer', {
          [s.isSliderItem]: isSliderItem,
          [s.twoRowSlider]: isMobileView && indexInList % 2 === 0,
        })}
        onClick={() => onClick?.()}
      >
        {/* eslint-disable-next-line jsx-a11y/alt-text */}
        <Image
          src={thumbnail}
          size="small"
          aspectRatio={aspectRatio || 290 / 193}
          className={s.collectionImage}
          {...imageProps}
        >
          <div className={s.collectionInfo}>
            <div className={s.headerContainer}>
              <div className={s.subHeader}>{subHeader}</div>
              <h1 className={s.header}>{header}</h1>
            </div>
            {link.action === 'redirect' && (
              <div className={s.btnContainer}>
                <Button variant="primary-neon" size={isMobileView ? 'small' : 'medium'}>
                  <span>
                    {link.label} ({link.productsCount})
                  </span>
                </Button>
              </div>
            )}
          </div>
        </Image>
      </div>
    </Link>
  )
}

export { CollectionCard }
