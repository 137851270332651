import React, { FC } from 'react'
import cn from 'classnames'
import Link from 'next/link'

import { Image } from 'ui/image'

import { AspectRatio } from 'components/aspect-ratio'

import { useGlobalContext } from 'lib/context/global-context'

import s from './styles.module.scss'

type InterestCardProps = {
  loading?: boolean
  data?: any
  name?: any
  thumbnail?: any
  isSliderItem?: boolean
  imageProps?: any
  onClick?: () => void
  aspectRatio?: number | null
  indexInList?: number
}

const InterestCard: FC<InterestCardProps> = ({
  loading,
  data,
  isSliderItem = false,
  imageProps,
  onClick,
  aspectRatio = 0.7,
  indexInList = 0,
  children,
}) => {
  const { isMobileView } = useGlobalContext()

  if (loading) {
    return (
      <div className={cn(s.card, s.loading)}>
        {/* TODO in SSR while copying this, declare below size constants in constant file */}
        <AspectRatio ratio={aspectRatio || 290 / 193}>
          <div className={s.productImage} />
        </AspectRatio>
        <div className={s.cardInfo}>
          <div className={s.label} />
        </div>
      </div>
    )
  }

  if (!data) return null

  const { label, thumbnail, url } = data || {}

  return (
    <Link passHref href={`/${url}`}>
      <a>
        <div
          className={cn(s.card, 'cursor-pointer', {
            [s.isSliderItem]: isSliderItem,
            [s.twoRowSlider]: isMobileView && indexInList % 2 === 0,
          })}
          onClick={() => onClick?.()}
        >
          {/* eslint-disable-next-line jsx-a11y/alt-text */}
          <Image
            src={thumbnail}
            size="small"
            aspectRatio={aspectRatio}
            className={s.cardImage}
            {...imageProps}
          ></Image>

          <div className={s.cardInfo}>
            <div className={s.label}>{label}</div>
          </div>
          {children}
        </div>
      </a>
    </Link>
  )
}

export { InterestCard }
