import { Icon } from 'ui/icon'
import { Image } from 'ui/image'

import { DestinationLink } from 'components/destination-link'

import s from './styles.module.scss'

type DestinationByRegionCardProps = {
  data: RegionDestination
  onClick?: () => void
}

const DestinationByRegionCard = ({ data, onClick }: DestinationByRegionCardProps) => {
  return (
    <div className={s.container}>
      <DestinationLink
        destinationId={data.destinationId}
        underline={false}
        onClick={onClick}
        className={s.destinationByRegionCard}
      >
        <div className={s.destinationImageContainer}>
          <Image
            src={data.thumbnail}
            size="medium"
            alt={data.name}
            layout="fill"
            lazyLoad
            className={s.destinationImage}
          />
        </div>
        <div className={s.body}>
          <Icon name="map-pin-filled" size="small" />
          <span>{data.name}</span>
        </div>
      </DestinationLink>
    </div>
  )
}

export { DestinationByRegionCard }
