import React from 'react'
import cn from 'classnames'

import { Image } from 'ui/image'

import { AspectRatio } from 'components/aspect-ratio'
import { DestinationLink } from 'components/destination-link'

import { noop } from 'lib/utils'

import s from './styles.module.scss'

export type DestinationCardProps = {
  loading?: boolean
  data?: CountryDestination
  isSliderItem?: boolean
  imageProps?: any
  onClick?: () => void
  aspectRatio?: number | null
}

const DestinationCard = ({
  loading,
  data,
  isSliderItem = false,
  imageProps = {},
  onClick = noop,
  aspectRatio,
}: DestinationCardProps) => {
  if (loading) {
    return (
      <div className={cn(s.card, s.loading)}>
        <AspectRatio ratio={aspectRatio || 290 / 193}>
          <div className={s.productImage} />
        </AspectRatio>
        <div className={cn(s.productInfo)}>
          <div className={s.countryName} />
          <div className={s.description} />
        </div>
      </div>
    )
  }

  if (!data) return null

  const { destinationName, destinationId, destinationMetaData, mediaData } = data
  return (
    <DestinationLink destinationId={destinationId} onClick={() => onClick?.()} underline={false}>
      <div className={cn(s.card, 'cursor-pointer', { [s.isSliderItem]: isSliderItem })}>
        {/* eslint-disable-next-line jsx-a11y/alt-text */}
        <Image
          size="medium"
          src={mediaData?.[0]}
          aspectRatio={aspectRatio || 290 / 193}
          className={s.productImage}
          {...imageProps}
        />

        <div className={cn(s.productInfo)}>
          <h1 className={s.countryName}>{destinationName}</h1>
          <div className={s.description}>{destinationMetaData.description}</div>
        </div>
      </div>
    </DestinationLink>
  )
}

export { DestinationCard }
