import React, { FC } from 'react'

import { LazyLoad } from 'components/lazy-load'

interface ExposureTrackerProps {
  onExposure: () => void
  deps?: any[]
  bottomOffset?: string
}

const Tracker: FC<ExposureTrackerProps> = ({ onExposure, deps = [] }) => {
  React.useEffect(onExposure, deps) // eslint-disable-line react-hooks/exhaustive-deps
  return null
}

const ExposureTracker = (props: ExposureTrackerProps) => (
  <LazyLoad edge="bottom" offset={props.bottomOffset}>
    <Tracker {...props} />
  </LazyLoad>
)

export { ExposureTracker }
