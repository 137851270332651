import React, { useState, useRef, useEffect, ReactNode } from 'react'
import cn from 'classnames'

import s from './styles.module.scss'

const LazyLoad = ({
  children,
  edge = 'bottom',
  offset = '0px',
  skipLazyLoad = false,
  // this is used for safari fix because
  // somehow in PDP image slider, bottom absolute is overtaken by slider hence
  // our lazyload point is not exposed on screen
  // this props render a point on DOM so that safari doesn't ignore rendering
  hiddenPoint = false,
  viewOnScreen,
}: {
  children: ReactNode
  edge?: 'top' | 'bottom'
  offset?: string
  skipLazyLoad?: boolean
  hiddenPoint?: boolean
  viewOnScreen?: () => void
}) => {
  const [isInViewport, setIsInViewport] = useState(false)
  const elementRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (!elementRef.current || isInViewport || skipLazyLoad || !window?.IntersectionObserver) return

    const element = elementRef.current
    const rootMargin = edge === 'bottom' ? `0px 0px ${offset} 0px` : `${offset} 0px 0px 0px`
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsInViewport(entry.isIntersecting)
      },
      { rootMargin }
    )
    observer.observe(element)
    return () => observer.unobserve(element)
  }, [isInViewport, offset, edge, skipLazyLoad])

  useEffect(() => {
    if (isInViewport) viewOnScreen?.()
  }, [viewOnScreen, isInViewport])

  return (
    <>
      <div
        ref={elementRef}
        className={cn('observer', s.observerElement, {
          [s._top]: edge === 'top',
          [s._bottom]: edge === 'bottom',
          [s._hiddenPoint]: hiddenPoint,
        })}
      />
      {skipLazyLoad ? children : isInViewport && children}
    </>
  )
}

export { LazyLoad }
