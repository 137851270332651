import React, { PropsWithChildren, forwardRef } from 'react'
import cn from 'classnames'
import Slider, { Settings } from 'react-slick'

import { Sizes } from 'ui/icon'

import { noop } from 'lib/utils'

import { MOBILE_VIEW_BREAKPOINT } from 'lib/constants'

import SliderArrow from './slider-arrow'

import s from './styles.module.scss'

// Import css files
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

interface ResponsiveSettings {
  breakpoint: number
  settings: any
}

// Note: copy pasted this whole component module as it is from traveller repo
interface SlickSliderProps extends Settings {
  wrapperClassName?: string
  className?: string
  arrows?: boolean
  arrowSize?: Sizes
  dots?: boolean
  responsive?: ResponsiveSettings[]
  afterChange?: (index: number) => void
  beforeChange?: (currentSlide: number, nextSlide: number) => void
}

const SlickSlider = forwardRef<any, PropsWithChildren<SlickSliderProps>>(
  (
    {
      children,
      wrapperClassName = '',
      arrows = true,
      arrowSize = 'small',
      dots,
      centerPadding,
      responsive,
      infinite = false,
      afterChange = noop,
      beforeChange = noop,
      ...props
    },
    ref
  ) => {
    const breakPoint600Settings =
      responsive?.[0]?.breakpoint === MOBILE_VIEW_BREAKPOINT ? responsive?.[0]?.settings : {}
    const settings: any = {
      infinite,
      dots,
      afterChange,
      beforeChange,
      centerPadding: centerPadding || 0,
      responsive: [
        {
          breakpoint: MOBILE_VIEW_BREAKPOINT,
          settings: {
            initialSlide: 0,
            centerPadding: '16px',
            ...breakPoint600Settings,
          },
        },
        ...(responsive || []),
      ],
      ...props,
    }

    if (arrows) {
      const arrowProps = { dots, arrowSize }
      // @ts-ignore
      settings.nextArrow = props?.nextArrow || <SliderArrow {...arrowProps} /> // onClick and className received from SlickSlider itself, which we can't pass here, but TS is not aware here
      // @ts-ignore
      settings.prevArrow = props?.prevArrow || <SliderArrow isPrev {...arrowProps} />
    }
    return (
      <div className={cn(s.sliderWrapper, wrapperClassName)}>
        <Slider {...settings} ref={ref}>
          {children}
        </Slider>
      </div>
    )
  }
)
SlickSlider.displayName = 'SlickSlider'

export { SlickSlider }
