import React, { ReactElement } from 'react'
import cn from 'classnames'

import { Icon, Sizes } from 'ui/icon'

import s from './styles.module.scss'

const Callout = ({
  variant = 'blue',
  label,
  isTooltip,
  size = 'medium',
  bold = false,
  onMouseEnter,
  onMouseLeave,
  onClick,
  disabled,
}: {
  label: string | ReactElement
  isTooltip?: boolean
  size?: 'mini' | 'small' | 'medium' | 'semi-large' | 'large'
  variant?: Variant
  bold?: boolean
  disabled?: boolean
  onMouseEnter?: (event: React.MouseEvent) => void
  onMouseLeave?: (event: React.MouseEvent) => void
  onClick?: (event: React.MouseEvent) => void
}) => {
  const iconSize: Record<string, Sizes> = {
    mini: 'mini',
    small: 'mini',
    medium: 'small',
    'semi-large': 'small',
    large: 'small',
  }

  return (
    <div
      className={cn(s.container, s[`${variant}`], { 'cursor-pointer': isTooltip })}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
    >
      <div className={cn(s.item, s[`item-${size}`], { [s.bold]: bold, [s.disabled]: disabled })}>
        <div>{label}</div>
        {isTooltip && <Icon name="information-outline" size={iconSize[size]} />}
      </div>
    </div>
  )
}

export { Callout }
