import React, { FC } from 'react'
import cn from 'classnames'

import useDateCurrency from 'lib/hooks/useDateCurrency'

import s from './styles.module.scss'

const StrikedOffPrice: FC<{
  currency?: string
  total?: number
  discount?: number
  discountType?: 'percentage'
  position?: 'left' | 'right'
}> = ({ currency, total, discount, position = 'left' }) => {
  const { formatNumber } = useDateCurrency()

  if (!total || total === 0 || !discount || discount === 0) return null

  // Calculate original total from discounted price
  const preDiscountPrice = (total / (1 - discount / 100))?.toFixed(2)

  return (
    <span className={cn(s.strikedOffPrice, [`m${position === 'left' ? 'l' : 'r'}-1`])}>
      {currency} {formatNumber(preDiscountPrice)}
    </span>
  )
}

export { StrikedOffPrice }
