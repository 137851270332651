import { Button } from 'ui/button'

import KfLogo from 'brand-assets/illustrations/logo/kf-standalone-logo.svg'
import SiaLogo from 'brand-assets/illustrations/sia-logo-black.svg'
import ScootLogo from 'brand-assets/illustrations/logo/scoot-logo.svg'

import s from './styles.module.scss'

export type ExclusiveBenefitData = {
  id: 'kf' | 'sia' | 'scoot'
  iconName: string
  subHeader: string
  description: string
  link: {
    type: 'button'
    label: string
    isPseudoUrl: boolean
  }
}

type ExclusiveBenefitsCardProps = {
  data: ExclusiveBenefitData
  onClick?: () => void
}

const logos = {
  KFLogo: KfLogo,
  SIALogo: SiaLogo,
  ScootLogo: ScootLogo,
}

const ExclusiveBenefitsCard = ({ data, onClick }: ExclusiveBenefitsCardProps) => {
  const { iconName, subHeader, description, link } = data

  const LogoIcon = logos[iconName as keyof typeof logos]

  return (
    <div className={s.container}>
      <div className={s.exclusiveBenefitsCard}>
        <div className={s.header}>
          <div className={s.logoContainer}>{LogoIcon && <LogoIcon className={s.logo} />}</div>
          <Button variant="secondary" size="small" onClick={onClick}>
            {link.label}
          </Button>
        </div>
        <div className={s.body}>
          <span className={s.subHeader}>{subHeader}</span>
          <p className={s.description}>{description}</p>
        </div>
      </div>
    </div>
  )
}

export { ExclusiveBenefitsCard }
