import { FC, ReactNode } from 'react'
import cn from 'classnames'
import { ErrorBoundary } from 'react-error-boundary'

import { Chip } from 'ui/chip'
import { ChipsContainer } from 'ui/chip/container'

import { ExposureTracker } from 'components/exposure-tracker'

import { useGlobalContext } from 'lib/context/global-context'
import { logError } from 'lib/utils'
import { ComponentModifier, ModifierTypeDataMap } from 'lib/@Types'

import { EVENTS } from 'lib/constants/events'

import s from './styles.module.scss'

// only get those props which will not be passed by the modifier
type ChipProps = Omit<
  React.ComponentProps<typeof Chip>,
  'id' | 'selected' | 'key' | 'iconName' | 'label' | 'onChange'
>

type ComponentProps = {
  modifier: ComponentModifier<'pills'> | undefined
  onChange: (payload: ModifierTypeDataMap['pills']) => void
  trackEvent: TrackEventType
  fullWidth?: boolean
  componentEventId?: string
  children?: ReactNode
} & ChipProps

const PillsModifier: FC<ComponentProps> = ({
  modifier,
  onChange,
  trackEvent,
  fullWidth = true,
  componentEventId,
  children,
  ...chipProps
}) => {
  const { isMobileView } = useGlobalContext()

  if (!modifier?.data?.length) return null

  return (
    <ErrorBoundary FallbackComponent={() => null} onError={logError}>
      <div className={cn(s.pills, { [s.reducedWidth]: !fullWidth })}>
        <ChipsContainer showArrows={!isMobileView} fullWidth={fullWidth}>
          {modifier?.data?.map?.((item: ModifierTypeDataMap['pills'], index: number) => {
            return (
              <Chip
                {...chipProps}
                key={item.id || `${item.label}-${index}`}
                id={item.id}
                selected={item.id === modifier.current?.id}
                iconName={item.icon || modifier.icon}
                label={item.label}
                onClick={() => {
                  trackEvent({
                    attributeId: modifier.modifierId,
                    attributeValue: {
                      filterType: modifier.modifierId,
                      selection: item.id,
                      componentEventId: componentEventId,
                    },
                    eventType: EVENTS.TYPE.CLICK,
                    attributeType: EVENTS.ATTRIBUTES_TYPE.FILTER,
                  })
                  onChange?.(item)
                }}
                className={s.pill}
              />
            )
          })}
          {children}
        </ChipsContainer>

        <ExposureTracker
          onExposure={() => {
            trackEvent?.({
              attributeId: modifier.modifierId,
              attributeValue: {
                filterType: modifier.modifierId,
                filters: modifier.data,
                componentEventId: componentEventId,
              },
              eventType: EVENTS.TYPE.EXPOSURE,
              attributeType: EVENTS.ATTRIBUTES_TYPE.FILTER,
            })
          }}
        />
      </div>
    </ErrorBoundary>
  )
}

export { PillsModifier }
