import React, { useEffect, useMemo, useState } from 'react'
import cn from 'classnames'

import { Link } from 'components/link'

import { buildPath, isBrowser, isChromeBrowser } from 'lib/utils'
import { useGlobalContext } from 'lib/context/global-context'

import { PRODUCT_ROUTE } from 'lib/constants/routes'

import s from './styles.module.scss'

interface ProductDetailsLinkProps extends React.DOMAttributes<HTMLAnchorElement> {
  className?: string
  children: React.ReactNode
  productId?: string
  productUri?: string
  destinationId?: string
  openInNewTab?: boolean
  underline?: boolean
}

const ProductDetailsLink = ({
  className,
  children,
  productId,
  productUri,
  destinationId,
  openInNewTab = true,
  underline = true,
  ...restProps
}: ProductDetailsLinkProps) => {
  const { isMobileView, isTabletView } = useGlobalContext()
  const [hydrated, setHydrated] = useState(false)

  let productSlug = `${productId}-${productUri}`
  if (destinationId) productSlug += `-${destinationId}`

  useEffect(() => {
    setHydrated(true)
  }, [])

  // Check if it's Chrome on mobile or tablet
  const isMobileTabletChrome = useMemo(
    () =>
      hydrated
        ? (isMobileView || isTabletView) && isChromeBrowser(isBrowser ? window?.navigator?.userAgent : '')
        : false,
    [isMobileView, isTabletView, hydrated]
  )

  // Open in new tab if it's not Chrome on mobile or tablet
  const shouldOpenInNewTab = openInNewTab && !isMobileTabletChrome

  return (
    <Link href={buildPath(PRODUCT_ROUTE, { productSlug })} passHref>
      <a
        className={cn(s.link, className, { [s.underline]: underline })}
        {...restProps}
        {...(shouldOpenInNewTab && { target: '_blank' })}
      >
        {children}
      </a>
    </Link>
  )
}

export { ProductDetailsLink }
