import React from 'react'
import cn from 'classnames'

import { Icon } from 'ui/icon'

import { useHorizontalScroll } from 'lib/hooks/useHorizontalScroll'

import s from './styles.module.scss'

interface ChipsContainerProps {
  children: React.ReactNode
  showArrows?: boolean
  fullWidth?: boolean
  variant?: 'light-ghost' | 'dark-ghost'
}

const ChipsContainer = ({
  showArrows = true,
  variant = 'light-ghost',
  children,
  fullWidth = true,
}: ChipsContainerProps) => {
  const { ref, isStartPosition, isEndPosition, scrollToLeft, scrollToRight } = useHorizontalScroll()

  return (
    <div className={cn(s.chipsContainer, s[`${variant}`])}>
      {showArrows && (
        <div
          className={cn(s.sliderArrowContainer, s.previous, {
            [s.hidden]: isStartPosition,
          })}
        >
          <div
            className={cn(s.sliderArrow, s.sliderArrowPrevious, { [s.withoutBorder]: !fullWidth })}
            onClick={scrollToLeft}
          >
            <Icon name="caret-left" size={fullWidth ? 'small' : 'medium'} />
          </div>
        </div>
      )}

      <div className={s.chipsContent} ref={ref}>
        {children}
      </div>

      {showArrows && (
        <div
          className={cn(s.sliderArrowContainer, s.next, {
            [s.hidden]: isEndPosition,
          })}
        >
          <div
            className={cn(s.sliderArrow, s.sliderArrowNext, { [s.withoutBorder]: !fullWidth })}
            onClick={scrollToRight}
          >
            <Icon name="caret-right" size={fullWidth ? 'small' : 'medium'} />
          </div>
        </div>
      )}
    </div>
  )
}

export { ChipsContainer }
