import React, { FC } from 'react'
import cn from 'classnames'

import { Callout } from 'components/callouts/callout'

import useDateCurrency from 'lib/hooks/useDateCurrency'

import s from './styles.module.scss'

const PercentDiscoutCallout: FC<{
  position?: 'left' | 'right'
  discount?: number
  discountType?: 'percentage'
  calloutSize?: 'mini' | 'small'
}> = ({ position = 'left', discount, discountType = 'percentage', calloutSize = 'small' }) => {
  const { formatNumber } = useDateCurrency()
  if (!discount || discount === 0) return null

  const sign = discountType.toLowerCase() === 'percentage' ? '%' : ''

  const _discountValue = formatNumber(discount, {
    trailingZeroDisplay: 'stripIfInteger',
    maximumFractionDigits: 1,
    roundingMode: 'trunc',
  } as Intl.NumberFormatOptions)

  return (
    <span className={cn(s.percentOff, [`m${position === 'left' ? 'l' : 'r'}-2`])}>
      <Callout variant="dark-green" size={calloutSize} label={`-${_discountValue}${sign}`} />
    </span>
  )
}

export { PercentDiscoutCallout }
